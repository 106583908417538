import {useState, useEffect, React, forwardRef} from 'react';
import {useParams} from 'react-router-dom';
import ReactPlayer from "react-player/youtube";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as _ThemeProvider from "react-bootstrap/ThemeProvider";
import * as classNames from "classnames";


const VideoDialog = forwardRef(({
                                  bsPrefix,
                                  className,
                                  contentClassName,
                                  centered,
                                  size,
                                  fullscreen,
                                  children,
                                  scrollable,
                                  dialogStyle,
                                  ...props
                                }, ref) => {
  bsPrefix = (0, _ThemeProvider.useBootstrapPrefix)(bsPrefix, 'modal');
  const dialogClass = `${bsPrefix}-dialog`;
  const fullScreenClass = typeof fullscreen === 'string' ? `${bsPrefix}-fullscreen-${fullscreen}` : `${bsPrefix}-fullscreen`;
  return <div ref={ref} style={dialogStyle}
              className={classNames(dialogClass, className, size && `${bsPrefix}-${size}`, centered && `${dialogClass}-centered`, scrollable && `${dialogClass}-scrollable`, fullscreen && fullScreenClass)}>
    <div className={classNames(`${bsPrefix}-content`, contentClassName)}>
      {children}
    </div>
  </div>
});


const VideoPlayer = ({title, videoUrl}) => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };
  const [dimensions, setDimensions] = useState(calculateDimensions());

  function calculateDimensions() {
    var windowHeight, targetHeight, maxWidth, aspectRatio;
    aspectRatio = 16 / 9;
    windowHeight = window.innerHeight;
    targetHeight = Math.min(720, windowHeight - 80);
    maxWidth = targetHeight * aspectRatio;
    console.log("width: " + maxWidth + ", height: " + targetHeight);

    return {width: maxWidth, height: targetHeight};
  }

  useEffect(() => {
    function handleResize() {
      setDimensions(calculateDimensions());
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });

  const videoUrlObject = new URL(videoUrl);
  const videoUrlQuery = videoUrlObject.searchParams;
  const videoUrlSearchParams = new URLSearchParams(videoUrlQuery);
  const videoStart = videoUrlSearchParams.get('start');
  const videoEnd = videoUrlSearchParams.get('end');
  let youtubePlayerVars = {
    autoplay: 1,
    vq: "hd720",
    mute: null,
    widgetid: null,
    modestbranding: null,
    controls: null,
    playsinline: null,
    showinfo: null
  };
  if (videoStart !== null) {
    youtubePlayerVars.start = videoStart;
  }
  if (videoEnd !== null) {
    youtubePlayerVars.end = videoEnd;
  }

  return (
    <Modal dialogStyle={{width: dimensions.width + "px"}} animation={false} fullscreen={false} show={true} onHide={handleClose} dialogAs={VideoDialog}
           dialogClassName="video-player-modal" backdropClassName="video-player-modal-backdrop" centered>
      <Modal.Body >
        <div className='player-wrapper'><ReactPlayer className='react-player' widgetid={null} controls={null}
                                                     playsinline={null} showinfo={null} url={videoUrl} config={{
          youtube: {
            playerVars: youtubePlayerVars
          }
        }} width='100%' height='100%'/></div>
      </Modal.Body>
      <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}><FontAwesomeIcon
        icon={faXmark}/></button>
    </Modal>
  );
};

export default VideoPlayer;