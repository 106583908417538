import axios from "axios";
import {authHeader} from "./auth-util";
import {getServerUrl} from "../Util";

const sendTellAFriend = (email) => {
  var formData = new FormData();
  formData.append('email', email);

  return axios({
      method: 'post',
      url: getServerUrl("/api/learning/tell_a_friend/tell_a_friend"),
      data: formData,
      headers: authHeader()
    }
  )
    .then((response) => {
      return response.data;
    });
};

const tellAFriendService = {
  sendTellAFriend,
};

export default tellAFriendService;