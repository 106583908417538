import {React, useEffect, useState} from 'react';
import ErrorModal from "../components/ErrorModal";
import UserService from "../services/user.service";
import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
import {getServerUrl} from "../Util";
import CertificationImage from "../components/CertificationImage";

const Certification = () => {
  const [certificationDetails, setCertificationDetails] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  let display = '';
  const handleClose = function () {
    navigate('/');
  };

  useEffect(() => {
    UserService.getCertificationDetails().then(
      (response) => {
        if (response.data.success) {
          setCertificationDetails(response.data.data);
        } else {
          setError(true);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  if (error) {
    display = <ErrorModal title="Error" message="Unable to retrieve certification details. Please try again."/>;
  } else if (certificationDetails) {
    if (certificationDetails.certified) {
      let certificationNumber = certificationDetails.details.certification_number;
      let imageUrl = getServerUrl('/certification/' + certificationNumber + '/preview');
      let pdfUrl = getServerUrl('/certification/' + certificationNumber + '/pdf');
      let addToLinkedInUrl = certificationDetails.add_to_linked_in_url;
      let linkedInImageSrc = "/images/linkedin_icon_90px_high.png";

      display = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Certification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-end"><a href={pdfUrl} target="_blank" className="btn btn-primary">Download PDF</a><a href={addToLinkedInUrl} className="add-to-linked-in" target="_blank" title="Add this certificate to your LinkedIn profile."><img src={linkedInImageSrc} alt="Add this certificate to your LinkedIn profile." /></a></p>
          <CertificationImage imageUrl={imageUrl} className="certification-image"/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>;

    } else {
      display = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Certification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Complete all exercises to access your certificate.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>;
    }
  }

  return display;
}

export default Certification;