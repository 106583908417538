import {useState, useEffect, React} from 'react';
import {useParams} from 'react-router-dom';
import ExamsService from "../services/exams.service";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router";

const LearningMaterial = ({exam}) => {
  const [learningMaterial, setLearningMaterial] = useState(null);
  const navigate = useNavigate();
  const handleClose = function() {navigate('/');};

  useEffect(() => {
    ExamsService.getLearningMaterial(exam.id).then(
      (response) => {
        setLearningMaterial(response.data.data);
      },
      (error) => {
        setLearningMaterial(null);
      }
    );
  }, []);

  var learningMaterialDisplay = '';
  if (learningMaterial) {
    learningMaterialDisplay = <div dangerouslySetInnerHTML={{__html: learningMaterial}}></div>;
  }

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Learning Material</Modal.Title>
      </Modal.Header>
      <Modal.Body>{learningMaterialDisplay}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

export default LearningMaterial;