import {useState, useEffect, React} from 'react';
import {Navigate} from 'react-router-dom';
import UserService from "../services/user.service";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
import {useAuth} from "../hooks/useAuth";

const Profile = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  const handleClose = function() {navigate('/');};

  useEffect(() => {
    if (!user) {
      setUserDetails(null);
    } else {
      UserService.getUserDetails().then(
        (response) => {
          setUserDetails(response.data.data);
        },
        (error) => {
          setUserDetails(null);
        }
      );
    }
  }, []);

  if (!user) {
    return <Navigate to="/login"/>;
  }

  var userDetailsDisplay = '';
  if (userDetails) {
    userDetailsDisplay = <div>
      <p>
        <strong>Token:</strong> {user.accessToken.substring(0, 20)} ...{" "}
        {user.accessToken.substr(user.accessToken.length - 20)}
      </p>
      <p>
        <strong>Email:</strong> {userDetails.email}
      </p>
      <p>
        <strong>First name:</strong> {userDetails.firstName}
      </p>
      <p>
        <strong>Last name:</strong> {userDetails.lastName}
      </p>
    </div>
  }

  return (
    <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>{userDetailsDisplay}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

export default Profile;