import {useOutletContext} from "react-router";
import TheoryExam from "../pages/TheoryExam";
import TheoryResult from "../pages/TheoryResult";

export default function TheoryResultRoute() {
  const [exam] = useOutletContext()

  return (
    <TheoryResult exam={exam} />
  )
}