import {useOutletContext} from "react-router";
import PracticalExamIncompletePrerequisites from "../pages/PracticalExamIncompletePrerequisites";


export default function PracticalExamIncompletePrerequisitesRoute() {
  const [exam] = useOutletContext()

  return (
    <PracticalExamIncompletePrerequisites exam={exam} />
  );
}