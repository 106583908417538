import React, { useEffect, useState } from 'react';
import ExamsListItem from "./ExamsListItem";
import ExamsListIntroItem from "./ExamsListIntroItem";
import { useExams } from "../hooks/useExams";
import UserService from '../services/user.service';

export default function ExamsList() {
  const [userCertified, setUserCertified] = useState(false);
  const { getCombinedExamsResults } = useExams();
  const combinedExamsResults = getCombinedExamsResults();
  let completedIntroductionVideo;
  var listItems = [];
  var itemIndex = 1;

  useEffect(() => {
    UserService.getCertificationDetails().then(
      (response) => {
        if (response.data.success) {
          let certificationDetails = response.data.data;
          if (certificationDetails.certified) {
            setUserCertified(true);
          }
          else {
            setUserCertified(false);
          }
        }
        else {
          setUserCertified(false);
        }
      },
      (error) => {
        setUserCertified(false);
      }
    )
  }, []);

  if (combinedExamsResults.dataLoaded) {
    completedIntroductionVideo = false;
    if (combinedExamsResults.introductionVideo) {
      completedIntroductionVideo = combinedExamsResults.introductionVideo.completed;
      listItems.push(<div key="intro" className="col-sm-6 col-lg-3"><ExamsListIntroItem
        introductionVideo={combinedExamsResults.introductionVideo} completedIntroductionVideo={completedIntroductionVideo} itemIndex={itemIndex++} /></div>);
    }

    combinedExamsResults.examResults.forEach(function (combinedExamResult) {
      let showNextSteps = userCertified && (itemIndex - 1 === combinedExamsResults.examResults.length);
      listItems.push(<div key={combinedExamResult.exam.id} className="col-sm-6 col-lg-3"><ExamsListItem combinedExamResult={combinedExamResult} completedIntroductionVideo={completedIntroductionVideo} itemIndex={itemIndex++} showNextSteps={showNextSteps} /></div>);
    });

    return (
      <div className="exam-list">
        <div className="row g-3">
          {listItems}
        </div>
      </div>
    )
  }
  else {
    return <div></div>
  }
}