import {useLoaderData, useOutlet} from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import usePageTracking from "../hooks/usePageTracking";

export const AuthLayout = () => {
  const outlet = useOutlet();
  usePageTracking();

  return (
    <AuthProvider>{outlet}</AuthProvider>
  );
};