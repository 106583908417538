import React, { useEffect, useState } from 'react';
import ExamsService from "../services/exams.service";
import { useLocation, useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import LoadingOverlay from "../components/LoadingOverlay";
import ErrorModal from "../components/ErrorModal";

export default function PracticalExamSubmit({ exam }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let practicalExamDisplay = '';
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };
  const location = useLocation();

  useEffect(() => {
    const submissionFile = location.state.submissionFile;
    if (submissionFile) {
      setLoading(true);
      ExamsService.submitPracticalExam(exam, submissionFile).then(
        (response) => {
          if (response.success) {
            if (response.data.complete) {
              window.location.href = "/exam/" + exam.id + "/practical/result";
            } else {
              if (response.data.message) {
                setErrorMessage(response.data.message);
              }
              setError(true);
            }
          } else {
            setError(true);
          }
        },
        (error) => {
          setError(true);
        }
      );
    }
    else {
      setError(true);
    }
  }, []);

  if (error) {
    let defaultErrorMessage = "The exam could not be submitted.";
    practicalExamDisplay =
      <ErrorModal title="Practical Exam Submission Error" message={errorMessage ? errorMessage : defaultErrorMessage} />;
  } else if (loading) {
    practicalExamDisplay = <LoadingOverlay message="Your exam is being submitted..." />;
  } else if (exam) {
    practicalExamDisplay = '';
  }

  return (
    practicalExamDisplay
  )
}