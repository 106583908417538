import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useExams } from "../hooks/useExams";
import { useNavigate } from "react-router";
import { getServerUrl } from '../Util';
import UserService from "../services/user.service";
import LoadingOverlay from '../components/LoadingOverlay';
import ErrorModal from '../components/ErrorModal';


const CertificationComplete = (() => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [certificationDetails, setCertificationDetails] = useState(null);
  const { getCombinedExamsResults } = useExams();
  const combinedExamsResults = getCombinedExamsResults();
  const navigate = useNavigate();
  const handleClose = function () {
    window.location.href = '/';
  };

  useEffect(() => {
    setLoading(true);
    UserService.getCertificationDetails().then(
      (response) => {
        setLoading(false);
        if (response.data.success) {
          let certificationDetails = response.data.data;
          if (certificationDetails.certified) {
            setCertificationDetails(response.data.data);
          }
          else {
            navigate('/');
          }
        }
        else {
          setError(true);
        }
      },
      (error) => {
        setLoading(false);
        setError(true);
      }
    );
  }, []);

  let progressScore = 0;
  let grade = 'F';
  let linkedInButton = '';
  let downloadPdfButton = '';
  let tellAFriendButton = '';
  let displayContent = '';

  if (error) {
    displayContent = <ErrorModal title="Certification Error" message="Your certification data could not be loaded." />;
  }
  else if (loading) {
    displayContent = <LoadingOverlay message="Loading certification data." />;
  }
  else if (combinedExamsResults.dataLoaded && certificationDetails !== null) {
    let certificationNumber = certificationDetails.details.certification_number;
    let addToLinkedInUrl = certificationDetails.add_to_linked_in_url;
    linkedInButton = <a href={addToLinkedInUrl} className="btn btn-primary" target="_blank" rel="noreferrer" title="Add this certificate to your LinkedIn profile.">Add to LinkedIn Profile</a>;
    let pdfUrl = getServerUrl('/certification/' + certificationNumber + '/pdf');
    downloadPdfButton = <a className="btn btn-primary" href={pdfUrl} target="_blank" rel="noreferrer">Download PDF</a>;
    tellAFriendButton = <a className="btn btn-primary" href="/certification_tell_a_friend">Tell A Friend</a>;
    progressScore = combinedExamsResults.progressScore;
    grade = certificationDetails.grade;

    let feedbackButton = <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://forms.gle/PYY1m5U3h8qarESv9">Provide Feedback</a>;
    let modanoButtons = <div>
      <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://www.modano.com/get-started">Try Modano For Free</a>
      <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://www.modano.com/contact-us">Contact Modano</a>
    </div>;

    let certificationButtons = <div>
      {linkedInButton}
      {downloadPdfButton}
      {tellAFriendButton}
    </div>;

    let modelButtonLink = "https://app.modano.com/files/resources_learning_files/financial_modeling_fundamentals/Modano-Financial%20Modeling%20Fundamentals-Modular.xlsb";
    let modelButton = <a className="btn btn-primary" target="_blank" rel="noreferrer" href={modelButtonLink}>View More Detailed Modular Model</a>;

    displayContent = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Training Course Complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="certification-complete">
          <p>Congratulations on successfully completing the Financial Modeling Fundamentals course with an overall score of {`${progressScore}%`} and a grade of {grade}.</p>
          <p>We'd love to hear your feedback:</p>
          {feedbackButton}
          <p>Now that you know your way around a financial model, try Modano to automate your financial models while also accessing thousands of advanced financial modeling templates, example models, and more:</p>
          {modanoButtons}
          <p>Share your new certification with the word and tell friends about this training course:</p>
          {certificationButtons}
          <p>View a more detailed example of the same model built with the Modano Excel add-in in less than 10 minutes:</p>
          {modelButton}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>;
  }

  return displayContent;
});

export default CertificationComplete;