import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuildingColumns, faCloudArrowDown, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import PracticalSubmitButton from "./PracticalSubmitButton";
import { formatSecondsDuration } from "../Util";
import * as classNames from "classnames";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { isDesktopScreen } from '../hooks/useUtils';

export default function ExamsListItem({ combinedExamResult, completedIntroductionVideo, itemIndex, showNextSteps }) {
  let exam = combinedExamResult.exam;
  let theoryResult = combinedExamResult.theoryResult;
  let practicalResult = combinedExamResult.practicalResult;
  let learningMaterialButton = '';
  let pdfUrl = '/exam/' + exam.id + '/pdf';
  let pdfButton = '';
  let examVideoUrl = '/exam/' + exam.id + '/video';
  let videoButton = '';
  let theoryUrl = '/exam/' + exam.id + '/theory';
  let theoryButton = <Link to={theoryUrl} className="start-theory-button"><FontAwesomeIcon
    icon={faBuildingColumns} /></Link>;

  let practicalDownloadUrl = '/exam/' + exam.id + '/practical/download';
  let practicalIncompletePrerequisitesUrl = '/exam/' + exam.id + '/practical/incomplete_prerequisites';
  let practicalDownloadButton = '';
  let practicalSubmitButton = '';
  let nextStepsButton = '';

  let theoryScore = '-';
  let practicalScore = '-';
  let header;
  let videoDurationDisplay;
  let itemClasses = ['exam-item'];

  if (theoryResult && theoryResult.taken) {
    theoryScore = theoryResult.score;
    theoryScore += "%";
  }

  if (exam.includePractical) {
    if (!isDesktopScreen()) {
      let incompatibleMessage = 'Your device is too small to undertake practical training exercises. Please use your laptop or desktop computer to do these exercises.';
      practicalSubmitButton = <Link to={'/incompatible_device'} state={{ message: incompatibleMessage }} className='practical-submit-button'><FontAwesomeIcon
        icon={faCloudArrowUp} /></Link>
      practicalDownloadButton = <Link to={'/incompatible_device'} state={{ message: incompatibleMessage }} className="practical-download-button"><FontAwesomeIcon
        icon={faCloudArrowDown} /></Link>;
    }
    else {
      practicalDownloadButton =
        <Link to={practicalDownloadUrl} className="practical-download-button"><FontAwesomeIcon
          icon={faCloudArrowDown} /></Link>;

      if (combinedExamResult.completedAllPrerequisites && completedIntroductionVideo) {
        itemClasses.push('access');
        practicalSubmitButton = <PracticalSubmitButton exam={exam} />;
      } else {
        itemClasses.push('no-access');
        practicalSubmitButton =
          <Link to={practicalIncompletePrerequisitesUrl} className="practical-submit-button"><FontAwesomeIcon
            icon={faCloudArrowUp} /></Link>;
      }
    }

    if (combinedExamResult.completed) {
      itemClasses.push('complete');
    } else {
      itemClasses.push('incomplete');
    }

    if (practicalResult && practicalResult.taken) {
      practicalScore = practicalResult.score;
      practicalScore += "%";
    }
  }

  if (exam.learningMaterialAvailable) {
    //learningMaterialButton = <Link to={learningMaterialUrl} className="btn btn-link learning-material-button" data-exam-id={exam.id}>Learning Material</Link>;
  }

  if (exam.video) {
    videoDurationDisplay = exam.video.duration != null ? formatSecondsDuration(exam.video.duration) : '';
    header = <Link to={examVideoUrl} className="header video">
      <h5>{itemIndex + ". " + exam.title}</h5>
      {videoDurationDisplay && (
        <span className="video-duration">{videoDurationDisplay}</span>
      )
      }
    </Link>;
    videoButton = <Link to={examVideoUrl} className="video-button"><FontAwesomeIcon
      icon={faYoutube} /></Link>;
  } else {
    header = <div className="header">
      <h5>{itemIndex + ". " + exam.title}</h5>
    </div>
  }


  if (exam.learningMaterialPdfUrl !== "") {
    pdfButton = <Link to={pdfUrl} className="pdf-button"><FontAwesomeIcon
      icon={faFilePdf} /></Link>;
  }

  if (showNextSteps) {
    itemClasses.push('next-steps');
    nextStepsButton = <Link to="certification_complete" className="next-steps-button btn btn-sm">Next Steps</Link>
  }

  return (
    <div className={classNames(itemClasses)}>
      {header}

      <div className="description" dangerouslySetInnerHTML={{ __html: exam.description }} />
      <p className="scores"><span className="label">Practical:</span> <span className="score">{practicalScore}</span> <span className="label">Theory:</span><span className="score">{theoryScore}</span></p>
      <div className="bottom">
        <div className="buttons">
          {pdfButton}
          {learningMaterialButton}
          {practicalDownloadButton}
          {videoButton}
          {practicalSubmitButton}
          {theoryButton}
        </div>
        {nextStepsButton}
      </div>
    </div>
  );
}