import {Route, useLoaderData, useOutlet} from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import Home from "../pages/Home";
import React from "react";

export const ModalLayout = () => {
  const outlet = useOutlet();

  return (
    <div>
      {outlet}
    </div>
  );
};