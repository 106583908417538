import {React} from 'react';

import {Modal, Button} from "react-bootstrap";
import {useNavigate} from "react-router";

const PrerequisiteModal = ({examUserAccess}) => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  const prerequisitesList = examUserAccess.incompletePrerequisiteExams.map((prerequisiteExam) =>
    <li>{prerequisiteExam.title}</li>
  );

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Incomplete Prerequisites</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please complete the following exercises before attempting this exercise:</p>
        <ul>
          {prerequisitesList}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PrerequisiteModal;