export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken };
  } else {
    return {};
  }
}

export function authAccessToken() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return user.accessToken;
  } else {
    return '';
  }
}

export function removeAccessToken() {
  localStorage.removeItem("user");
}