import React, { useEffect, useState } from 'react';
import ExamsService from "../services/exams.service";
import { useNavigate } from "react-router";
import { Button, Modal } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import PracticalFeedbackTable from "../components/PracticalFeedbackTable";
import UserService from "../services/user.service";

export default function PracticalResult({ exam }) {
  const [practicalResult, setPracticalResult] = useState(null);
  const [error, setError] = useState(false);
  let display = '';
  const navigate = useNavigate();

  const handleClose = function () {
    UserService.getCertificationDetails().then(
      (response) => {
        if (response.data.success) {
          let certificationDetails = response.data.data;
          if (certificationDetails.certified) {
            navigate('/certification_complete');
          }
          else {
            navigate("/");
          }
        }
        else {
          navigate("/");
        }
      },
      (error) => {
        navigate("/");
      }
    );
  }

  useEffect(() => {
    ExamsService.getPracticalResult(exam.id).then(
      (response) => {
        if (response.data.success) {
          setPracticalResult(response.data.data);
        } else {
          setError(true);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  display = '';

  if (error) {
    display = <ErrorModal title="Error" message="Unable to show exam results." />
  } else if (practicalResult) {


    display = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{exam.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Score: {practicalResult.score}</p>
        <PracticalFeedbackTable exam={exam} practicalResult={practicalResult} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  }

  return display;
}