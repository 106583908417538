import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
import ExamsService from "../services/exams.service";
import ErrorModal from "../components/ErrorModal";
import IncompleteIntroductionModal from "../components/IncompleteIntroductionModal";
import PrerequisiteModal from "../components/PrerequisiteModal";

export default function PracticalExamIncompletePrerequisites({exam}) {
  const [examUserAccess, setExamUserAccess] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const handleClose = function () {
    window.location.href = "/"; // Reload page as we may need server data to update client data if we reached this page.
  };
  let display = '';

  useEffect(() => {
    ExamsService.getExamUserAccess(exam.id).then(
      (response) => {
        if (response.data.success) {
          setExamUserAccess(response.data.data);
        }
        else {
          setExamUserAccess(null);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  if (error) {
    display = <ErrorModal title="Error" message={<p>An error has occurred. Please try again.</p>} />
  }
  else if (examUserAccess) {
    if (examUserAccess.completedIntroductionVideo == false) {
      display = <IncompleteIntroductionModal />
    }
    else if (examUserAccess.incompletePrerequisiteExams.length > 0) {
      display = <PrerequisiteModal examUserAccess={examUserAccess} />
    }
    else {
      display = <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Incomplete Prerequisites</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please complete all prerequisites before attempting this exercise.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>;
    }
  }

  return display;
}