import {React} from 'react';
import VideoPlayer from "./VideoPlayer";
import ErrorModal from "../components/ErrorModal";

const ExamVideo = ({exam}) => {

  if (exam.video == null) {
    return <ErrorModal title="Error" message="Unable to play video" />
  }

  return <VideoPlayer title={exam.title} videoUrl={exam.video.url}/>
}

export default ExamVideo;