import axios from "axios";
import {authHeader} from "./auth-util";
import {getServerUrl} from "../Util";

const getUserDetails = () => {
  return axios.get(getServerUrl("/api/learning/user/details"), {headers: authHeader()});
};

const setIntroductionCompleted = () => {
  return axios({
      method: 'post',
      url: getServerUrl("/api/learning/user/introduction_completed"),
      data: '',
      headers: authHeader()
    }
  )
    .then((response) => {
      return response.data;
    });
};

const getCertificationDetails = () => {
  return axios.get(getServerUrl("/api/learning/user/certification_details"), {headers: authHeader()});
};

const userService = {
  getCertificationDetails,
  getUserDetails,
  setIntroductionCompleted,
};

export default userService;