import { useNavigate, useOutlet } from "react-router-dom";
import Home from "../pages/Home";
import React from "react";
import Navbar from "../components/NavBar";

export const HomeLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  return (
    <div>
      <div className="header-wrapper">
        <div className="container">
          <div className="header">
            <Navbar />
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="page-title">Financial Modeling Fundamentals</h1>
        <p className="sub-title">This free training course teaches you how to build and understand an integrated 3-way financial model of any business.</p>
        <Home />
      </div>
      {outlet}
      <div className="fixed-footer footer-wrapper fixed-bottom">
        <div className="container">
          <div className="footer d-flex flex-wrap justify-content-between align-items-center">
            <img className="powered-by-modano" src="/images/powered_by_modano.svg" alt="Powered By Modano" />
            <button className="btn btn-sm d-none d-md-inline-block tell-a-friend-btn mx-auto" onClick={() => navigate("tell_a_friend")}>Tell A Friend</button>
            <a className="btn btn-sm btn-primary" href="https://www.modano.com/trial/signup">Try Modano For Free</a>
          </div>
        </div>
      </div>
    </div>
  );
};