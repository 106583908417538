import {useOutletContext} from "react-router";
import PracticalExamDownload from "../pages/PracticalExamDownload";


export default function PracticalExamDownloadRoute() {
  const [exam] = useOutletContext()

  return (
    <PracticalExamDownload exam={exam} />
  )
}