import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  const location = useLocation();

  // Initialize React Ga with your tracking ID
  ReactGA.initialize('G-ZK0G3X65Y5');

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    console.log('Update page tracking: ' + location.pathname + location.search);
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search});

  }, [location]);
};

export default usePageTracking;