import axios from "axios";
import {authAccessToken, authHeader} from "./auth-util";
import {getServerUrl} from "../Util";

const getExams = () => {
  return axios.get(getServerUrl("/api/learning/exam/list"), {headers: authHeader()});
};

const getExam = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId), {headers: authHeader()});
};

const getExamUserAccess = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId + "/user_access"), {headers: authHeader()});
};

const getLearningMaterial = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId + "/learning_material"), {headers: authHeader()});
};

const getTheoryResult = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId + "/theory/result"), {headers: authHeader()});
};

const getPracticalResult = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId + "/practical/result"), {headers: authHeader()});
};

const startTheoryExam = (examId) => {
  return axios({
      method: 'post',
      url: getServerUrl("/api/learning/exam/" + examId + "/theory/start"),
      data: '',
      headers: authHeader()
    }
  )
    .then((response) => {
      return response.data;
    });

};

const submitTheoryExam = (theoryExamAttempt) => {
  let bodyFormData = new FormData();
  let headers = authHeader();
  headers["Content-Type"] = "multipart/form-data";
  bodyFormData.append('theory_attempt', JSON.stringify(theoryExamAttempt));

  return axios({
      method: 'post',
      url: getServerUrl("/api/learning/exam/" + theoryExamAttempt.exam.id + "/theory/submit"),
      data: bodyFormData,
      headers: headers
    }
  )
    .then((response) => {
      return response.data;
    });
};

const getResults = () => {
  let headers = authHeader();

  return axios({
      method: 'get',
      url: getServerUrl("/api/learning/results"),
      headers: headers
    }
  )
    .then((response) => {
      return response.data;
    });
};

const getResultsReport = () => {
  let headers = authHeader();

  return axios({
      method: 'get',
      url: getServerUrl("/api/learning/results_report"),
      headers: headers
    }
  )
    .then((response) => {
      return response.data;
    });
};

const getPracticalExamQuestionFile = (examId) => {
  return axios.get(getServerUrl("/api/learning/exam/" + examId + "/practical/download_question_file"), {
    responseType: 'blob',
    headers: authHeader()
  });
};

const submitPracticalExam = (exam, file) => {
  let bodyFormData = new FormData();
  let headers = authHeader();
  headers["Content-Type"] = "multipart/form-data";
  bodyFormData.append('file', file, file.name);

  return axios({
      method: 'post',
      url: getServerUrl("/api/learning/exam/" + exam.id + "/practical/submit_answer_file"),
      data: bodyFormData,
      headers: headers
    }
  )
    .then((response) => {
      return response.data;
    });
};

const getExamFromList = (examId, exams) => {
  var result = exams.find(exam => {
    return exam.id === examId
  })

  return result;
}

const getIncompletePreRequisites = (exam, exams, results) => {
  let incompletePreRequisites = [];
  const prerequisiteExamIds = exam.prerequisiteExams;

  prerequisiteExamIds.forEach(function(prerequisiteExamId, index, preRequisiteArray) {
    let theoryResult = null;
    let practicalResult = null;
    let completedTheory = false;
    let completedPractical = false;

    let prerequisiteExam = getExamFromList(prerequisiteExamId, exams);
    if (prerequisiteExam !== undefined) {
      if (results) {
        if (results.theory.exams.hasOwnProperty(prerequisiteExamId)) {
          theoryResult = results.theory.exams[prerequisiteExamId];
          if (theoryResult.taken && theoryResult.score > 50) {
            completedTheory = true;
          }
        }

        if (exam.includePractical) {
          if (results.practical.exams.hasOwnProperty(prerequisiteExamId)) {
            practicalResult = results.practical.exams[prerequisiteExamId];
            if (practicalResult.taken && practicalResult.score > 50) {
              completedPractical = true;
            }
          }
        } else {
          completedPractical = true;
        }
      }

      if (!completedTheory || !completedPractical) {
        incompletePreRequisites.push()
      }
    }
  });

  return incompletePreRequisites;
}


const examsService = {
  getExams,
  getExam,
  getExamUserAccess,
  getIncompletePreRequisites,
  getLearningMaterial,
  getResults,
  getResultsReport,
  getPracticalExamQuestionFile,
  startTheoryExam,
  submitTheoryExam,
  getTheoryResult,
  submitPracticalExam,
  getPracticalResult,
};

export default examsService;