import {useOutletContext} from "react-router";
import ExamVideo from "../pages/ExamVideo";


export default function ExamVideoRoute() {
  const [exam] = useOutletContext()

  return (
    <ExamVideo exam={exam} />
  )
}