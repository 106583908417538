import React, {useRef} from 'react';
import {faCloudArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "@react-pdf-viewer/core";
import {useNavigate} from "react-router";

export default function PracticalSubmitButton({exam}) {
  const inputFile = useRef(null);
  const navigate = useNavigate();
  function handleClick() {
    inputFile.current.click();
  }

  function handleFileChange(e) {
    if (e.target.files) {
      navigate("/exam/" + exam.id + "/practical/submit", {state: {submissionFile: e.target.files[0]}});
    }
  }

  return <span>
    <a onClick={handleClick} className="practical-download-button"><FontAwesomeIcon
      icon={faCloudArrowUp}/></a>
  <input type="file" ref={inputFile} style={{display: 'none'}} onChange={handleFileChange}/>
  </span>

}