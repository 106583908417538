import {useOutletContext} from "react-router";
import TheoryExam from "../pages/TheoryExam";
import PracticalResult from "../pages/PracticalResult";

export default function PracticalResultRoute() {
  const [exam] = useOutletContext()

  return (
    <PracticalResult exam={exam} />
  )
}