import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";
import UserAuthenticationService from "../services/UserAuthenticationService";
import {authAccessToken} from "../services/auth-util";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    window.location.href = "/";
  };

  // call this function to sign out logged in user
  const logout = () => {
    if (user !== null) {
      UserAuthenticationService.logoutUser(authAccessToken())
        .then((response) => {
          setUser(null);
          window.location.href = "/";
        })
        .catch(() => {
          setUser(null);
          window.location.href = "/";
        });
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};