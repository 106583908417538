import React, {useEffect, useState} from 'react';
import TheoryExamForm from "../components/TheoryExamForm";
import {useParams} from 'react-router-dom';
import ExamsService from "../services/exams.service";
import {useNavigate} from "react-router";
import {Modal} from "react-bootstrap";

export default function TheoryExam({exam}) {
  const [theoryExamAttempt, setTheoryExamAttempt] = useState(null);
  let theoryExamFormDisplay = '';
  const navigate = useNavigate();

  useEffect(() => {
    ExamsService.startTheoryExam(exam.id).then(
      (response) => {
        if (response.success) {
          setTheoryExamAttempt(response.data);
        }
        else {
          setTheoryExamAttempt(null);
        }
      },
      (error) => {
        setTheoryExamAttempt(null);
      }
    );
  }, []);

  if (theoryExamAttempt) {
    theoryExamFormDisplay = <TheoryExamForm theoryExamAttempt={theoryExamAttempt} />
  }

  return theoryExamFormDisplay;
}