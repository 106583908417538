const FMFProgressBar = (props) => {
  const {completed} = props;

  return (
    <div className="fmf-progress">
      <span className="fmf-progress-label">Overall Score: {`${completed}%`}</span>
    </div>
  );
};

export default FMFProgressBar;