import {React} from 'react';

import {Modal, Button} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

const IncompleteIntroductionModal = ({examUserAccess}) => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Introduction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please watch the introduction video before attempting any exercises.</p>
      </Modal.Body>
      <Modal.Footer>
        <Link to="/intro/video" className="btn btn-primary">Watch Video</Link>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IncompleteIntroductionModal;