import { React } from 'react';
import { Modal, Button } from "react-bootstrap";

const MaintenanceMode = (() => {
  const handleClose = function () {
    window.location.href = '/';
  };

  let contactModanoLink = <a target="_blank" href='https://www.modano.com/contact-us'>contact Modano</a>;

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Maintenance Mode</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Due to high demand this training course has been put in maintenance mode. We apologize for the inconvenience.</p>
        <p>Please try again in 24 hours or {contactModanoLink} if you have an immediate financial modeling requirement.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
});

export default MaintenanceMode;