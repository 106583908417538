import React, { useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {Button, Modal} from "react-bootstrap";
import UserAuthenticationService from "../services/UserAuthenticationService";
import {useAuth} from "../hooks/useAuth";
import {useLocation} from "react-router";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const handleClose = function() {navigate('/');};
  const { user, login } = useAuth();

  let initialValues = {
    email: "",
    password: "",
  };

  // Set default email value
  if (urlSearchParams.has("email")) {
    initialValues.email = urlSearchParams.get("email");
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue) => {
    const { email, password } = formValue;
    setLoading(true);

    UserAuthenticationService.loginUser(email, password)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          login(response.data);
        }
        else {
          setErrorMessage(response.error);
        }
        //navigate("/profile");
        //window.location.href = "/profile";
      })
      .catch(() => {
        setLoading(false);
        //if (response.hasOwnProperty('error')) {
          //setErrorMessage(response.error);
        //}
        //else {
          setErrorMessage("Unable to login. Please try again.");
       // }
      });
  };

  if (user) {
    return <Navigate to="/" />;
  }

  let loginForm = (
    <div className="col-md-12 login-form">
        <p>Enter your existing account credentials.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form id="login-form">
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group mb-3">
              <Link to="/request_password_reset">Forgotten password?</Link>
            </div>

            {errorMessage && (
              <div className="form-group mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
          </Form>
        </Formik>
    </div>
  );

  return (
    <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
      </Modal.Header>
      <Modal.Body>{loginForm}</Modal.Body>
      <Modal.Footer>
        <button form="login-form" type="submit" variant="primary" className="btn btn-primary" disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>Log In</span>
        </button>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Login;