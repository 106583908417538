import {useOutletContext} from "react-router";
import ExamVideo from "../pages/ExamVideo";
import ExamPdf from "../pages/ExamPdf";


export default function ExamPdfRoute() {
  const [exam] = useOutletContext()

  return (
    <ExamPdf exam={exam} />
  )
}