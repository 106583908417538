import {React} from 'react';

import {Modal, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router";

const LoadingOverlay = ({message}) => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static"
           backdropClassName="loading-overlay-backdrop" dialogClassName="loading-overlay-dialog" centered>
      <Modal.Body><p className="h5">{message}</p><Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner></Modal.Body>
    </Modal>
  );
};

export default LoadingOverlay;