import React, {useEffect, useState} from 'react';
import UserService from "../services/user.service";
import {Navigate} from "react-router-dom";
import ExamsService from "../services/exams.service";
import {useNavigate} from "react-router";
import {Modal} from "react-bootstrap";
import {useAuth} from "../hooks/useAuth";

export default function Report() {
  const { user } = useAuth();
  const [resultsReport, setResultsReport] = useState(null);
  const navigate = useNavigate();
  const handleClose = function() {navigate('/');};

  useEffect(() => {
    if (!user) {
      setResultsReport(null);
    } else {
      ExamsService.getResultsReport().then(
        (response) => {
          setResultsReport(response.data);
        },
        (error) => {
          setResultsReport(null);
        }
      );
    }
  }, []);

  if (!user) {
    return <Navigate to="/register"/>;
  }
  let reportTable = '';
  if (resultsReport) {
    let examRows = [];

    resultsReport.exams.forEach(function (currentValue, index, arr) {
      examRows.push(<tr>
        <td>{currentValue.title}</td>
        <td>{currentValue.attempts}</td>
        <td>{currentValue.total_questions}</td>
        <td>{currentValue.correct_answers}</td>
        <td>{currentValue.score}</td>
        <td>{currentValue.practical_attempts}</td>
        <td>{currentValue.practical_score}</td>
        <td>{currentValue.overall_score}</td>
      </tr>);
    });

    reportTable = <table>
      <tr>
        <th></th>
        <th colSpan={4}>Theory</th>
        <th colSpan={2}>Practical</th>
        <th></th>
      </tr>
      <tr>
        <th>Exam</th>
        <th>Attempts</th>
        <th>Questions</th>
        <th>Correct</th>
        <th>Score</th>
        <th>Attempts</th>
        <th>Score</th>
        <th>Overall Score</th>
      </tr>
      {examRows}

      <tr>
        <td>Total</td>
        <td>{resultsReport.total.attempts}</td>
        <td>{resultsReport.total.total_questions}</td>
        <td>{resultsReport.total.correct_answers}</td>
        <td>{resultsReport.total.score}</td>
        <td>{resultsReport.total.practical_attempts}</td>
        <td>{resultsReport.total.practical_score}</td>
        <td>{resultsReport.total.overall_score}</td>
      </tr>
    </table>;

  }

  return(
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>{reportTable}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}

