import React, {useState} from 'react';
import {faCheck, faCloudArrowUp, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareMinus, faSquarePlus} from "@fortawesome/free-regular-svg-icons";


export default function PracticalFeedbackTable({exam, practicalResult}) {
  const [expandedAreas, setExpandedAreas] = useState([]);
  let feedback = practicalResult.feedback;
  let feedbackRows = [];
  const expandIcon = <FontAwesomeIcon icon={faSquarePlus} />
  const collapseIcon = <FontAwesomeIcon icon={faSquareMinus} />
  
  function isExpanded(areaId) {
    return expandedAreas.includes(areaId);
  }
  
  function expandArea(areaId) {
    if (!isExpanded(areaId)) {
      let expandedAreasCopy = expandedAreas.map((x) => x);
      expandedAreasCopy.push(areaId);
      setExpandedAreas(expandedAreasCopy);
    }
  }

  function collapseArea(areaId) {
    let expandedAreasCopy = expandedAreas.filter(item => item !== areaId);
    setExpandedAreas(expandedAreasCopy);
  }

  function toggleArea(areaId) {
    if (isExpanded(areaId)) {
      collapseArea(areaId);
    }
    else {
      expandArea(areaId);
    }
  }

  feedback.MarkingAreas.forEach(function (markingArea, index, markingAreas) {
    let expanded = isExpanded(index);
    let areaCorrect = markingArea.Marks == markingArea.MaximumMarks;
    let areaClassName = "feedback-area";
    let expandToggle;
    let correctIcon = <FontAwesomeIcon icon={faCheck} />
    let incorrectIcon = <FontAwesomeIcon icon={faTimes} />
    let areaIcon;
    
    if (areaCorrect) {
      areaClassName += " correct";
      areaIcon = correctIcon;
    }
    else {
      areaClassName += " incorrect";
      areaIcon = incorrectIcon;
    }
    
    if (expanded) {
      expandToggle = collapseIcon
    }
    else {
      expandToggle = expandIcon
    }
    
    feedbackRows.push(<tr className={areaClassName} onClick={function() {toggleArea(index);}}>
      <td className="toggle-col">{expandToggle}</td>
      <td className="icon-col">{areaIcon}</td>
      <td className="description-col" colSpan="2">{markingArea.Title}</td>
      <td className="marks-col">{markingArea.Marks + "/" + markingArea.MaximumMarks}</td>
      <td className="score-col">{markingArea.Score + "%"}</td>
    </tr>);

    if (expanded) {
      markingArea.MarkingItems.forEach(function (markingItem, index, markingItems) {
        let itemClassName = "feedback-item";
        let itemIcon
        if (markingItem.IsCorrect) {
          itemClassName += " correct";
          itemIcon = correctIcon;
        }
        else {
          itemClassName += " incorrect";
          itemIcon = incorrectIcon;
        }

        feedbackRows.push(<tr className={itemClassName}>
          <td className="toggle-col"></td>
          <td className="icon-col"></td>
          <td className="icon-col">{itemIcon}</td>
          <td className="description-col">{markingItem.Description}</td>
          <td className="marks-col">{markingItem.Marks + "/" + markingItem.MaximumMarks}</td>
          <td className="score-col">-</td>
        </tr>);
      });
    }

  });

  feedbackRows.push(<tr className="total-row">
    <td className="toggle-col"></td>
    <td colSpan={3}>Total</td>
    <td className="marks-col">{feedback.Marks + "/" + feedback.MaximumMarks}</td>
    <td className="score-col">{feedback.Score + "%"}</td>
  </tr>);

  return (
    <table className="practical-feedback-table">
      <thead>
      <tr>
        <th className="toggle-col">{expandIcon}</th>
        <th colSpan={3}>Marking area</th>
        <th className="marks-col">Marks</th>
        <th className="score-col">Score</th>
      </tr>
      </thead>
      <tbody>
      {feedbackRows}
      </tbody>
    </table>
  );
}