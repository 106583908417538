const FMFTracker = (props) => {
  const {steps, completed, inProgress} = props;
  const circleRadius = 10; // Includes border
  const spacingBetweenCircles = 30;
  const completedSteps = completed + Math.floor(inProgress / 2);
  const inProgressStep = inProgress % 2 === 1;
  const filledCircleStyle = {
    fill: '#B04525', stroke: '#B04525', strokeWidth: 2, strokeMiterlimit: 10,
  };

  const halfFilledCircleStyle = {
    fill: '#B04525', stroke: '#B04525', strokeWidth: 1, strokeMiterlimit: 10,
  };

  const unfilledCircleStyle = {
    fill: '#FFFFFF', stroke: '#B04525', strokeWidth: 2, strokeMiterlimit: 10,
  };

  const lineStyles = {
    fill:'none', stroke: '#B04525', strokeWidth:2.2358, strokeMiterlimit:10
  };

  let lineLength = steps > 1 ? (circleRadius * 2 + spacingBetweenCircles) * (steps -1) : 0;
  let viewboxWidth = lineLength + circleRadius * 2;
  let viewboxHeight = circleRadius * 2;
  let viewbox = "0 0 " + viewboxWidth + " " + viewboxHeight;

  let circles = [];
  for (let i = 0; i < steps; i++) {
    let cy = circleRadius;
    let cx = circleRadius + i * (circleRadius * 2 + spacingBetweenCircles);
    let cStyle = (i < completedSteps) ? filledCircleStyle : unfilledCircleStyle;
    circles.push(<circle className="circle" key={i} style={cStyle} cx={cx} cy={cy} r={circleRadius - 1} />);

    // Add a half filled circle if required
    if (i == completedSteps && inProgressStep) {
      circles.push(<path key={i + "a"} d={"M" + cx + "," + (cy - circleRadius) + " a1,1 0 0,0 " + "0," + (cy + circleRadius)} style={halfFilledCircleStyle} />);
    }
  }
  let lineX1 = circleRadius;
  let lineX2 = lineX1 + lineLength;
  let lineY = circleRadius;
  let line = <line className="line" style={lineStyles} x1={lineX1} y1={lineY} x2={lineX2} y2={lineY}/>;

  return (
    <svg version="1.1"
         x="0px" y="0px"
         viewBox={viewbox} >
      {line}
      {circles}
    </svg>
  );
  /*
  return (
    <svg version="1.1"
         x="0px" y="0px"
         viewBox={viewbox} >
      <circle style={filledCircleStyle} cx="13.5" cy="13.5" r="12.5"/>
      <line style={lineStyles} x1="13.7" y1="13.5" x2="412.3" y2="13.5"/>
      <circle style={unfilledCircleStyle} cx="412.3" cy="13.5" r="12.5"/>
      <circle style={unfilledCircleStyle} cx="279.3" cy="13.5" r="12.5"/>
      <circle style={unfilledCircleStyle} cx="146.4" cy="13.5" r="12.5"/>
</svg>
  );

   */
};

export default FMFTracker;