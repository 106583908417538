import React from 'react';

export default function TheoryExamFormPager({theoryExamAttempt, currentQuestionIndex, changeQuestion, showMissingAnswersWarning}) {

  const questions = theoryExamAttempt.questions;
  const responses = theoryExamAttempt.responses;
  const pagerDisplay = questions.map(function(question, index, questionArray) {
    let answered = responses.hasOwnProperty(question.id);
    let pagerClasses = [];
    if (index == currentQuestionIndex) {
      //pagerClasses.push('btn-primary');
      pagerClasses.push('current');
    }
    else {
      //pagerClasses.push('btn-secondary');
    }

    if (answered) {
      pagerClasses.push('answered');
    }
    else {
      pagerClasses.push('not-answered');
    }

    return <button key={question.id} className={pagerClasses.join(' ')} onClick={function() {changeQuestion(index); showMissingAnswersWarning(false)}} >{index + 1}</button>;
  });

  return (
    <div className="question-pager pager">{pagerDisplay}</div>
  )
}