import {Navigate, Outlet, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ExamsService from "../services/exams.service";
import {useAuth} from "../hooks/useAuth";
import PrerequisiteModal from "./PrerequisiteModal";
import ErrorModal from "./ErrorModal";
import IncompleteIntroductionModal from "./IncompleteIntroductionModal";

export default function ProtectedExamRoute({
                                         redirectPath = '/register',
                                         children,
                                       }) {
  const [examUserAccess, setExamUserAccess] = useState(null);
  const [error, setError] = useState(false);
  const params = useParams();
  const examId = params.examId;
  let display = '';
  const { user } = useAuth();

  useEffect(() => {
    ExamsService.getExamUserAccess(examId).then(
      (response) => {
        if (response.data.success) {
          setExamUserAccess(response.data.data);
        }
        else {
          setExamUserAccess(null);
        }
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  if (!user) {
    return <Navigate to={redirectPath} replace/>;
  }

  if (error) {
    display = <ErrorModal title="Error" message={<p>An error has occurred. Please try again.</p>} />
  }
  else if (examUserAccess) {
    if (examUserAccess.completedIntroductionVideo == false) {
      display = <IncompleteIntroductionModal />
    }
    else if (examUserAccess.incompletePrerequisiteExams.length > 0) {
      display = <PrerequisiteModal examUserAccess={examUserAccess} />
    }
    else {
      display = children ? children : <Outlet context={[examUserAccess.exam]}/>;
    }
  }

  return display;
};