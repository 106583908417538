  export function getServerUrl(path) {
    var basePath;
    basePath = process.env.REACT_APP_LEARNING_API_SERVER_URL;
    return basePath + path;
  };

  export function formatSecondsDuration(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const secondsDisplay = seconds.toString().padStart(2, '0');
    const minutesDisplay = minutes.toString().padStart(2, '0');
    const hoursDisplay = hours.toString().padStart(2, '0');
    let result;
    if (hours > 0) {
      result = hoursDisplay + ":" + minutesDisplay + ":" + secondsDisplay;
    }
    else {
      result = minutesDisplay + ":" + secondsDisplay;
    }

    return result;
  }