import { React } from 'react';

import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';

const IncompatibleDeviceModal = () => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };
  const location = useLocation();
  const { message } = location.state;
  const defaultMessage = 'This website does not support your current screen size. Please try a different device with a larger screen.';

  return (
    <Modal fullscreen={false} size='lg' show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Incompatible Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message !== undefined ? message : defaultMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default IncompatibleDeviceModal;