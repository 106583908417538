import {React} from 'react';
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Viewer, Worker} from "@react-pdf-viewer/core";

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import {toolbarPlugin} from "@react-pdf-viewer/toolbar";
import ErrorModal from "../components/ErrorModal";

const ExamPdf = ({exam}) => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  const toolbarPluginInstance = toolbarPlugin();
  const {renderDefaultToolbar, Toolbar} = toolbarPluginInstance;
  let display = '';

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    //EnterFullScreen: () => <></>,
    //EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  const learningMaterialPdfUrl = exam.learningMaterialPdfUrl;

  if (learningMaterialPdfUrl !== '') {
    let pdfViewerDisplay = (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
          }}
        >
          <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={learningMaterialPdfUrl} plugins={[toolbarPluginInstance]}/>
        </div>
      </Worker>
    );

    display = <Modal fullscreen={true} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{exam.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{pdfViewerDisplay}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>;

  } else {
    display = <ErrorModal title="Error" message="Unable to display PDF file."/>;
  }

  return display;
}

export default ExamPdf;