import { React } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingColumns, faCloudArrowDown, faCloudArrowUp, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf, faFileText } from '@fortawesome/free-regular-svg-icons';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";

const InstructionsModal = () => {
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  return (
    <Modal fullscreen={false} size='lg' show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Instructions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Complete each step of this training course before moving to the next step, as follows:</p>
        <ol>
          <li>Click the <FontAwesomeIcon icon={faCloudArrowDown} /> icon to download the starting model, then open it.</li>
          <li>Click the <FontAwesomeIcon icon={faYoutube} /> icon to play the training video, ideally on a 2nd monitor.</li>
          <li>When complete, save and click the <FontAwesomeIcon icon={faCloudArrowUp} /> icon to assess your modeling.</li>
          <li>Click the <FontAwesomeIcon icon={faBuildingColumns} /> icon to undertake theoretical assessment.</li>
        </ol>
        <p>You must pass practical and theoretical assessment for each step before proceeding to the next step.</p>
        <p>You can also click the <FontAwesomeIcon icon={faFilePdf} />, <FontAwesomeIcon icon={faFileText} /> and <FontAwesomeIcon icon={faBinoculars}/> icons in the Introduction step to download the exercise training materials, Apple annual report, and financial statements impacts analyzer, respectively.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InstructionsModal;