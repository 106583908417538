import {React} from 'react';

import {Modal, Button} from "react-bootstrap";
import {useNavigate} from "react-router";

const ErrorModal = ({title, message}) => {
  const handleClose = function () {
    window.location.href = '/';
  };

  return (
    <Modal fullscreen={false} size="lg" show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;