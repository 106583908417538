import React from 'react';
import ExamsList from "../components/ExamsList";

export default function Home() {

  return(
    <div className="home">
      <ExamsList />
    </div>
  )
};
