import React, { useCallback, useState } from 'react';
import TheoryExamFormPager from "./TheoryExamFormPager";
import TheoryExamFormQuestion from "./TheoryExamFormQuestion";
import ExamsService from "../services/exams.service";
import { useNavigate } from "react-router";
import Countdown from "react-countdown";
import { Modal, Button, Alert } from "react-bootstrap";
import ErrorModal from "./ErrorModal";
import LoadingOverlay from "./LoadingOverlay";

export default function TheoryExamForm({ theoryExamAttempt }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [startTime] = useState(Date.now());
  const [error, setError] = useState(false);
  const [showMissingAnswersWarning, setShowMissingAnswersWarning] = useState(false);
  const currentQuestion = theoryExamAttempt.questions[currentQuestionIndex];
  const questions = theoryExamAttempt.questions;
  const questionsCount = questions.length;
  const currentQuestionIndexDisplay = currentQuestionIndex + 1;
  const navigate = useNavigate();
  let display = '';
  const handleClose = function () {
    navigate('/');
  };
  const checkMissingAnswers = () => {
    let missingAnswersCount = 0;
    questions.forEach(question => {
      if (!theoryExamAttempt.responses.hasOwnProperty(question.id)) {
        missingAnswersCount++;
      }
    });
    return missingAnswersCount;
  };
  const handleSubmitTheoryExam = () => {
    let missingAnswersCount = checkMissingAnswers();
    if (missingAnswersCount === 0) {
      setShowMissingAnswersWarning(false);
      onComplete();
    }
    else {
      setShowMissingAnswersWarning(true);
    }
  };

  const onComplete = useCallback((state) => {
    // do something with "state"

    setSubmitting(true);
    ExamsService.submitTheoryExam(theoryExamAttempt).then(
      (response) => {
        if (response.success) {
          //navigate("/exam/" + theoryExamAttempt.exam.id + "/theory/result");
          window.location.href = "/exam/" + theoryExamAttempt.exam.id + "/theory/result";
        } else {
          setError(true);
        }
      },
      (error) => {
        setError(true);
      }
    );
  });
  const timeAllowedDisplay = theoryExamAttempt.exam.timeAllowed + " minutes";
  const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Out of time</span>;
    } else {
      // Render a countdown
      let secondsText = seconds;
      if (seconds < 10) {
        secondsText = '0' + seconds;
      }
      return <span>{minutes}:{secondsText}</span>;
    }
  };
  const timerDisplay = <Countdown
    date={startTime + theoryExamAttempt.exam.timeAllowed * 60 * 1000}
    renderer={timerRenderer}
    onComplete={onComplete}
  />

  const warningTimerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (theoryExamAttempt.exam.warningTime) {
      const currentTime = (hours * 60 + minutes) * 60 + seconds;
      const startDisplayTime = (theoryExamAttempt.exam.warningTime) * 60;
      const endDisplayTime = (theoryExamAttempt.exam.warningTime - 1) * 60 + 45;
      if (currentTime < startDisplayTime && currentTime > endDisplayTime) {
        let warningMessage = "You have 1 minute left!";
        if (theoryExamAttempt.exam.warningTime > 1) {
          warningMessage = "You have " + theoryExamAttempt.exam.warningTime + " minutes left!"
        }
        return <Alert variant="warning">{warningMessage}</Alert>
      }
    }
  };

  let warningTimerDisplay = null;
  if (theoryExamAttempt.exam.warningTime) {
    warningTimerDisplay = <Countdown
      date={startTime + theoryExamAttempt.exam.timeAllowed * 60 * 1000}
      renderer={warningTimerRenderer}
    />
  }

  if (error) {
    display = <ErrorModal title="Error" message="Unable to submit exam." />;
  } else if (submitting) {
    display = <LoadingOverlay message="Your exam submission is being assessed." />;
  } else {
    const previousQuestionAvailable = () => {
      return currentQuestionIndex > 0;
    }
    const nextQuestionAvailable = () => {
      return currentQuestionIndex < questions.length - 1;
    }
    const gotoPreviousQuestion = () => {
      if (previousQuestionAvailable()) {
        setShowMissingAnswersWarning(false);
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    };
    const gotoNextQuestion = () => {
      if (nextQuestionAvailable()) {
        setShowMissingAnswersWarning(false);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    };

    const getMissingAnswersText = () => {
      let missingAnswersCount = checkMissingAnswers();
      let missingAnswersText = '';
      if (missingAnswersCount === questionsCount) {
        missingAnswersText = "Are you sure you want to submit this exam? You have not answered any questions!";
      }
      else {
        missingAnswersText = "Are you sure you want to submit this exam? You have only answered " + (questionsCount - missingAnswersCount) + " out of " + questionsCount + " questions!";
      }
      return missingAnswersText;
    }

    const previousButton = <Button variation="secondary" onClick={gotoPreviousQuestion}
      disabled={!previousQuestionAvailable()}>Previous Question</Button>
    const nextButton = <Button className='me-auto' variation="secondary" onClick={gotoNextQuestion} disabled={!nextQuestionAvailable()}>Next
      Question</Button>
    const submitButton = <Button variation="primary" onClick={handleSubmitTheoryExam}>Submit</Button>
    const confirmSubmitButton = <Button variation="primary" className="ms-auto" onClick={onComplete}>Submit</Button>;
    const missingAnswersWarning = <div className={'missing-answers-warning ms-md-auto'}>
      <p>{getMissingAnswersText()}</p>
      {confirmSubmitButton}
    </div>
    display = <Modal fullscreen={true} show={true} onHide={handleClose} backdrop="static" className="theory-exam-form">
      <Modal.Header closeButton>
        <Modal.Title>{theoryExamAttempt.exam.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="exam-theory-form" className="exam-theory-form">
          <div className="exam-header">
            <div className="exam-info-wrapper clearfix">
              <div className="exam-subtitle">Theoretical Assessment Exercise</div>
              <ul className="exam-info">
                <li>Question: <b><span
                  className="question-count">{currentQuestionIndexDisplay} of {questionsCount}</span></b></li>
                <li>Time allowed: <b>{timeAllowedDisplay}</b></li>
                <li>Time remaining: <span className="time-remaining-countdown is-countdown">{timerDisplay}</span></li>
              </ul>
            </div>
          </div>
          <TheoryExamFormPager theoryExamAttempt={theoryExamAttempt} currentQuestionIndex={currentQuestionIndex}
            changeQuestion={setCurrentQuestionIndex} showMissingAnswersWarning={setShowMissingAnswersWarning} />
          {warningTimerDisplay}
          <TheoryExamFormQuestion theoryExamAttempt={theoryExamAttempt}
            questionIndexDisplay={currentQuestionIndexDisplay}
            theoryExamQuestion={currentQuestion} showMissingAnswersWarning={setShowMissingAnswersWarning} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showMissingAnswersWarning ? <span className='missing-answers-warning-small-text'><p >{getMissingAnswersText()}</p></span> : ''}
        {previousButton}
        {nextButton}
        {showMissingAnswersWarning ? missingAnswersWarning : submitButton}
        {showMissingAnswersWarning ? <span className='missing-answers-warning-small-button'>{confirmSubmitButton}</span> : ''}
      </Modal.Footer>
    </Modal>;
  }

  return display;
}