import React, { useReducer, useState } from 'react';
import { getServerUrl } from "../Util";
import { authAccessToken } from "../services/auth-util";
import ExamsListItem from "./ExamsListItem";
import { Spinner } from "react-bootstrap";

const LayoutImageRight = 1;
const LayoutImageBelow = 2;


export default function TheoryExamFormQuestion({ theoryExamAttempt, questionIndexDisplay, theoryExamQuestion, showMissingAnswersWarning }) {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const questionText = theoryExamQuestion.question;
  let questionImage = '';
  let layoutClass = 'question-text-only';

  function handleOptionChange(event) {
    theoryExamAttempt.responses[theoryExamQuestion.id] = event.target.value;
    showMissingAnswersWarning(false);
    forceUpdate();
  }


  if (theoryExamQuestion.fid !== "0") {
    let questionImageUrl = getServerUrl("/api/learning/exam/" + theoryExamAttempt.exam.id + "/theory/question_image/" + theoryExamQuestion.id) + "?access_token=" + authAccessToken();
    questionImage = <img src={questionImageUrl} style={imageLoaded ? {} : { display: 'none' }} onLoad={() => setImageLoaded(true)} alt='Question Image' />;

    if (theoryExamQuestion.layout == LayoutImageRight) {
      if (window.innerWidth >= 576) {
        layoutClass = 'question-image-right';
      }
      else {
        layoutClass = 'question-image-below';
      }
    } else if (theoryExamQuestion.layout == LayoutImageBelow) {
      layoutClass = 'question-image-below';
    }
  }

  var answerRadios = [];
  var radiosName = "question-answer-" + theoryExamQuestion.id;
  var selectedOption = null;
  if (theoryExamAttempt.responses.hasOwnProperty(theoryExamQuestion.id)) {
    selectedOption = theoryExamAttempt.responses[theoryExamQuestion.id];
  }
  for (const optionKey in theoryExamQuestion.answer_options) {
    if (theoryExamQuestion.answer_options.hasOwnProperty(optionKey)) {
      let optionId = "question-answer-" + optionKey;
      const optionText = theoryExamQuestion.answer_options[optionKey];
      const optionRadio = <input type="radio" name={radiosName} id={optionId} value={optionKey}
        checked={optionKey == selectedOption} onChange={handleOptionChange} />
      answerRadios.push(<p key={optionKey}>{optionRadio}<label htmlFor={optionId}>{optionText}</label></p>);
    }
  }

  return (
    <div className={"question " + layoutClass}>
      <h3>Question {questionIndexDisplay}</h3>
      <div className="question-markup">
        <div className="question-text" dangerouslySetInnerHTML={{ __html: questionText }} />
        <div className="form-radios">{answerRadios}</div>
      </div>
      <div className="question-image">
        <Spinner animation="border" role="status" style={imageLoaded ? { display: 'none' } : {}}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        {questionImage}
      </div>
    </div>
  )
}