import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import UserAuthenticationService from "../services/UserAuthenticationService";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ErrorModal from "../components/ErrorModal";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const handleClose = function () { navigate('/'); };

  let requestUuid;
  if (urlSearchParams.has("uuid")) {
    requestUuid = urlSearchParams.get("uuid");
  }

  useEffect(() => {
    UserAuthenticationService.getPasswordRequestDetails(requestUuid)
      .then((response) => {
        if (response.success) {
          setRequestDetails(response.data);
        }
        else {
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        setErrorMessage('Unable to load details. Please try again.');
      });
  }, []);

  if (confirmMessage) {
    return (
      <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirmMessage}
          <div className="form-group mb-3">
            <Link to="/login">Back to Login</Link>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  else if (requestDetails !== null) {

    let initialValues = {
      email: requestDetails.email,
      password: "",
      passwordConfirm: "",
    };

    const websiteName = requestDetails.fmfAccount ? 'Financial Modeling Fundamentals' : 'Modano';

    const validationSchema = Yup.object().shape({
      password: Yup.string().required("This field is required!"),
      passwordConfirm: Yup.string().required("This field is required!").oneOf([Yup.ref('password'), null], 'Passwords do not match.'),
    });

    const handlePasswordReset = (formValue) => {
      const { password } = formValue;
      setLoading(true);

      UserAuthenticationService.resetPassword(password, requestUuid)
        .then((response) => {
          setLoading(false);
          if (response.success) {
            var message = <p>Your {websiteName} account password has been successfully reset.</p>;
            setConfirmMessage(message);
          }
          else {
            setErrorMessage(response.error);
          }
        })
        .catch(() => {
          setLoading(false);
          setErrorMessage("Unable to reset password. Please try again.");
        });
    }

    let passwordResetForm = (
      <div className="col-md-12 login-form">
        <p>Enter a new password for your {websiteName} account.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handlePasswordReset}
        >
          <Form id="password-reset-form">
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <Field name="email" type="text" className="form-control" disabled={true} />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password" className="form-label">New password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="passwordConfirm" className="form-label">Confirm new password</label>
              <Field name="passwordConfirm" type="password" className="form-control" />
              <ErrorMessage
                name="passwordConfirm"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group mb-3">
              <Link to="/login">Back to Login</Link>
            </div>

            {errorMessage && (
              <div className="form-group mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
            {confirmMessage && (
              <div className="form-group mb-3">
                <div className="alert alert-secondary" role="alert">
                  {confirmMessage}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );

    return (
      <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
        </Modal.Header>
        <Modal.Body>{passwordResetForm}</Modal.Body>
        <Modal.Footer>
          <button form="password-reset-form" type="submit" variant="primary" className="btn btn-primary" disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Reset Password</span>
          </button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  else if (errorMessage) {
    let errorMessageElement = <p>{errorMessage}</p>;
    return <ErrorModal title="Financial Modeling Fundamentals" message={errorMessageElement} />;
  }
  else {
    return '';
  }

};

export default PasswordReset;