import {React, useEffect, useState} from 'react';
import {Spinner} from "react-bootstrap";

const CertificationImage = ({imageUrl}) => {
  const [imageLoading, setImageLoading] = useState(true);
  let display = '';

  display = <span className="certification-image">
    <img src={imageUrl} className="certification-image" style={{display: imageLoading ? "none" : "block"}} onLoad={function () {setImageLoading(false);}} />
    <div className="certification-image-loading" style={{display: imageLoading ? "block" : "none"}}>
      <p><Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner></p>
      <p>Loading certification...</p>
    </div>
  </span>

  return display;
}

export default CertificationImage;