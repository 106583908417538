import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import tellAFriendService from "../services/tell_a_friend.service";

const TellAFriend = ({returnUrl = "/"}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const handleClose = function () {
    navigate(returnUrl);
  };

  const validationSchema = Yup.object().shape({});

  let initialValues = {
    email: "",
  };

  const handleTellAFriend = (formValue) => {
    const {email} = formValue;
    setLoading(true);

    tellAFriendService.sendTellAFriend(email)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          navigate(returnUrl);
        } else {
          setErrorMessage(response.error);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage("Unable to send message. Please try again.");
      });
  };

  let tellAFriendForm = (
    <div className="col-md-12 tell-a-friend-form">
      <p></p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleTellAFriend}
      >
        <Form id="tell-a-friend-form">
          <div className="form-group mb-3">
            <label htmlFor="email" className="form-label">Enter an email address to tell a friend about Financial Modeling Fundamentals.</label>
            <Field name="email" type="text" className="form-control"/>
            <ErrorMessage
              name="email"
              component="div"
              className="alert alert-danger"
            />
          </div>
          {errorMessage && (
            <div className="form-group mb-3">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </div>
  );


  return (
    <Modal fullscreen={false} size='lg' show={true} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Tell A Friend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {tellAFriendForm}
      </Modal.Body>
      <Modal.Footer>
        <button form="tell-a-friend-form" type="submit" variant="primary" className="btn btn-primary"
                disabled={loading}>
          {loading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          <span>Tell A Friend</span>
        </button>
        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );

}

export default TellAFriend;