import React, { useState } from "react";
import UserAuthenticationService from "../services/UserAuthenticationService";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const PasswordResetRequest = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(false);
  const navigate = useNavigate();
  const handleClose = function () { navigate('/'); };

  let initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
  });

  const handlePasswordResetRequest = (formValue) => {
    const { email } = formValue;
    setLoading(true);

    UserAuthenticationService.requestPasswordReset(email)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          var message = <p>If {email} is a valid account, an email will be sent with instructions to reset your password.</p>;
          setConfirmMessage(message);
        }
        else {
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage("Unable to request password reset. Please try again.");
      });
  }

  if (confirmMessage) {
    return (
      <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  else {
    let passwordResetRequestForm = (
      <div className="col-md-12 login-form">
        <p>Enter the email address associated with your account to reset your password.</p>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handlePasswordResetRequest}
          initialValues={initialValues}
        >
          <Form id="password-reset-request-form">
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>
            <div className="form-group mb-3">
              <Link to="/login">Back to Login</Link>
            </div>

            {errorMessage && (
              <div className="form-group mb-3">
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              </div>
            )}
            {confirmMessage && (
              <div className="form-group mb-3">
                <div className="alert alert-secondary" role="alert">
                  {confirmMessage}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    );

    return (
      <Modal fullscreen={false} show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Financial Modeling Fundamentals</Modal.Title>
        </Modal.Header>
        <Modal.Body>{passwordResetRequestForm}</Modal.Body>
        <Modal.Footer>
          <button form="password-reset-request-form" type="submit" variant="primary" className="btn btn-primary" disabled={loading}>
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Reset Password</span>
          </button>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }

};

export default PasswordResetRequest;