import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileText } from '@fortawesome/free-regular-svg-icons';
import { faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { formatSecondsDuration } from "../Util";
import * as classNames from "classnames";

export default function ExamsListIntroItem({ introductionVideo, completedIntroductionVideo, itemIndex }) {
  let videoUrl = '/intro/video';
  let videoDuration = introductionVideo.duration;
  let videoDurationDisplay;
  let header;
  let itemClasses = ["exam-item", "intro-item", "access"];
  let buttonClasses = ["btn"];
  videoDurationDisplay = (videoDuration != null) ? formatSecondsDuration(videoDuration) : '';
  header = <Link to={videoUrl} className="header video">
    <h5>{itemIndex + ". Introduction"}</h5>
    {videoDurationDisplay && (
      <span className="video-duration">{videoDurationDisplay}</span>
    )}
  </Link>;
  let trainingPdfLink = 'https://app.modano.com/files/resources_learning_files/financial_modeling_fundamentals/Modano-Financial Modeling Fundamentals.pdf';
  let trainingPdfButton = <Link target="_blank" to={trainingPdfLink}><FontAwesomeIcon icon={faFilePdf} /></Link>
  let appleFormLink = 'https://app.modano.com/files/learning/financial_modeling_fundamentals/Modano-Financial%20Statement%20Impacts%20Analyser.xlsb';
  let appleFormButton = <Link target="_blank" to={appleFormLink}><FontAwesomeIcon icon={faFileText} /></Link>
  let financialStatementAnalyserLink = 'https://app.modano.com/files/learning/financial_modeling_fundamentals/Apple-Annual%20Report-FY20.pdf';
  let financialStatementAnalyserButton = <Link target="_blank" to={financialStatementAnalyserLink}><FontAwesomeIcon icon={faBinoculars} /></Link>

  if (completedIntroductionVideo) {
    itemClasses.push('complete');
    buttonClasses.push('btn-secondary');
  } else {
    itemClasses.push('incomplete');
    buttonClasses.push('btn-primary');
  }

  return (
    <div className={classNames(itemClasses)}>
      {header}
      <div className="description">
        <p>Read <Link to={'/instructions'}>these instructions</Link> then click the button below to start this 8-step training course.</p>
      </div>
      <div className="bottom"><Link to={videoUrl} className={classNames(buttonClasses)}>Get Started</Link><div className="buttons">{trainingPdfButton}{appleFormButton}{financialStatementAnalyserButton}</div></div>
    </div>
  );
}