import {React, useEffect} from 'react';
import VideoPlayer from "./VideoPlayer";
import ErrorModal from "../components/ErrorModal";
import {useExams} from "../hooks/useExams";
import UserService from "../services/user.service";

const IntroductionVideo = () => {
  const {getCombinedExamsResults, refreshData} = useExams();
  const combinedExamsResults = getCombinedExamsResults();

  useEffect(() => {
    UserService.setIntroductionCompleted().then(
      (response) => {
        if (response.success) {
          refreshData();
        }
      },
      (error) => {
        // Ignore error.
      }
    );
  }, []);

  if (combinedExamsResults.introductionVideo == null) {
    return <ErrorModal title="Error" message="Unable to play video"/>
  }

  return <VideoPlayer title="Introduction" videoUrl={combinedExamsResults.introductionVideo.url}/>
}

export default IntroductionVideo;