import React, {useEffect, useState} from 'react';
import ExamsService from "../services/exams.service";
import {useNavigate} from "react-router";
import fileDownload from 'js-file-download';
import LoadingOverlay from "../components/LoadingOverlay";
import ErrorModal from "../components/ErrorModal";

export default function PracticalExamDownload({exam}) {
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    ExamsService.getPracticalExamQuestionFile(exam.id).then(
      (response) => {
        fileDownload(response.data, exam.practicalQuestionFilename);
        navigate('/');
      },
      (error) => {
        setError(true);
      }
    );
  }, []);

  if (error) {
    return (
      <ErrorModal title="Error" message="The question file could not be created. Please try again." />
    );
  }

  return (
    <LoadingOverlay message="Your question file is being prepared." />
  )
}