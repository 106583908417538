import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../hooks/useAuth";
import FMFProgressBar from "./FMFProgressBar";
import FMFTracker from "./FMFTracker";
import { useExams } from "../hooks/useExams";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as classNames from "classnames";
import UserService from '../services/user.service';

export default function Navbar() {
  const { user, logout } = useAuth();
  const { getCombinedExamsResults } = useExams();
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  var logoutLink = '';
  var loginLink = '';
  var viewCertificateLink = '';
  var progressBar = '';
  var tracker = '';
  let completedCount = 0;
  let inProgressCount = 0;
  let examsCount = 0;
  let progressScore = 0;
  let email = '';

  useEffect(() => {
    if (user) {
      UserService.getUserDetails().then(
        (response) => {
          if (response.data.success) {
            setUserEmail(response.data.data.email);
          }
          else {
            setUserEmail(null);
          }
        },
        (error) => {
          setUserEmail(null);
        }
      );
    }
    else {
      setUserEmail(null);
    }
  }, [user]);

  const combinedExamsResults = getCombinedExamsResults();
  let navClasses = ['row', 'align-items-center'];

  if (user) {
    navClasses.push('logged-in');
    logoutLink = <a href="#" className="btn btn-sm btn-outline-secondary" onClick={logout}>
      Log Out
    </a>;
    email = <span className='user-email-wrapper d-none d-md-inline-block'>{userEmail}</span>;
  }
  else {
    navClasses.push('logged-out');
    loginLink = <a href="#" className="btn btn-sm btn-outline-secondary d-none d-sm-inline-block" onClick={() => navigate('/login')}>
      Login
    </a>;
  }

  if (combinedExamsResults.dataLoaded) {
    completedCount = combinedExamsResults.completedExamsCount;
    inProgressCount = combinedExamsResults.inProgressExamsCount;
    examsCount = combinedExamsResults.examsCount;
    if (combinedExamsResults.introductionVideo != null) {
      examsCount += 1;
      if (combinedExamsResults.introductionVideo.completed) {
        completedCount += 1;
      }
    }

    if (completedCount === 0) {
      navClasses.push('nothing-completed');
    }

    progressScore = combinedExamsResults.progressScore;
    progressBar = <span className="progress-wrapper"><FMFProgressBar completed={progressScore} /></span>;
    tracker = <span className="tracker-wrapper col-lg-4 d-lg-flex d-none">Progress: <span className="tracker-image"><FMFTracker
      steps={examsCount} completed={completedCount} inProgress={inProgressCount}/></span></span>;
    viewCertificateLink = <Link to="/certification" className="view-certificate-link"><FontAwesomeIcon
      icon={faFilePdf} /> View Certificate</Link>;
  }


  return (
    <nav className={classNames(navClasses)}>
      {tracker}
      <span className="col-12 col-lg-8 ms-auto">
        {email}
        {progressBar}
        <ul className="nav-links">
          {viewCertificateLink && (
            <li>{viewCertificateLink}</li>
          )}
          {logoutLink && (
            <li>{logoutLink}</li>
          )}
          {loginLink && (
            <li>{loginLink}</li>
          )}
        </ul>
      </span>
    </nav>
  )
}