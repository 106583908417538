import { useEffect, useState } from "react";
import UserAuthenticationService from "../services/UserAuthenticationService";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Modal } from "react-bootstrap";
import ErrorModal from "../components/ErrorModal";
import { useAuth } from "../hooks/useAuth";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({});
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const { login } = useAuth();
  const navigate = useNavigate();
  const handleClose = function () {
    navigate('/');
  };

  let uuid;
  if (urlSearchParams.has("uuid")) {
    uuid = urlSearchParams.get("uuid");
  }

  useEffect(() => {
    setLoading(true);
    UserAuthenticationService.verifyEmail(uuid)
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setRegistrationDetails(response.data);
        }
        else {
          setErrorMessage(response.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Unable to load email verification data. Please try again.');
      });
  }, [uuid]);

  const registerUser = function () {
    if (Object.keys(registrationDetails).length !== 0) {
      setLoading(true);
      UserAuthenticationService.registerUser(registrationDetails.first_name, registrationDetails.last_name, registrationDetails.email, registrationDetails.password)
        .then((response) => {
          setLoading(false);
          if (response.success) {
            login(response.data);
          } else {
            setErrorMessage('Unable to create account. Please try again.');
          }
        })
        .catch(() => {
          setLoading(false);
          setErrorMessage("Unable to create account. Please try again.");
        });
    }
    else {
      navigate('/');
    }
  }

  if (errorMessage) {
    return <ErrorModal title={'Email Verification Error'} message={errorMessage}></ErrorModal>;
  }
  else {
    let actionButton = <button type="submit" variant="primary" className="btn btn-primary" disabled={loading} onClick={handleClose}>
      {loading && (
        <span className="spinner-border spinner-border-sm"></span>
      )}
      <span>OK</span>
    </button>;
    let content = '';
    if (loading) {
      if (Object.keys(registrationDetails).length !== 0) {
        content = 'Creating account.'
      }
      else {
        content = 'Loading email verification data.';
      }
    }
    else if (!loading && Object.keys(registrationDetails).length !== 0) {
      registerUser();
    }
    return (
      <Modal fullscreen={false} size='lg' show={true} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Verify Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content}
        </Modal.Body>
        <Modal.Footer>
          {actionButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default VerifyEmail;